import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React from 'react';
import './app.scss';
import { HelperSession } from './session/helper-session';
import { render } from 'react-dom';
import { HelperStatusContext } from './contexts';
import { HelperOverlay } from './overlay/helper-overlay';
import { HelperStatusProvider } from './status/helper-status-provider';
export const App = (props) => {
    if (!props.session)
        return null;
    const { age, status, killReason, replacedBy } = props.session;
    return _jsxs(HelperStatusProvider, { uuid: props.session.uuid, initial: {
            age,
            status,
            killReason,
            replacedBy
        }, children: [_jsx(HelperStatusContext.Consumer, { children: ({ helperStatus }) => _jsx(HelperOverlay, { helperStatus: helperStatus }) }), _jsx("div", { className: "session-helper__component", children: _jsx(HelperSession, { session: props.session }) })] });
};
render(_jsx(App, { session: window.currentSession }), document.getElementById('polo-session-helper'));

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import React, { memo, useLayoutEffect } from 'react';
import './helper-overlay.scss';
import { HelperStatus } from '../contexts';
const setStyleToSiblings = (setStyle) => {
    const body = document.getElementsByTagName('body')[0];
    if (body) {
        Array.from(body.children)
            .forEach((child) => {
            if (!child.matches('#polo-session-helper'))
                setStyle(child.style);
        });
    }
};
const idleStatus = HelperStatus.EXPIRED;
export const HelperOverlay = memo((props) => {
    useLayoutEffect(() => {
        if (props.helperStatus === idleStatus) {
            setStyleToSiblings(style => style.filter = 'blur(5px)');
        }
        else {
            setStyleToSiblings(style => style.filter = '');
        }
    }, [props.helperStatus]);
    if (props.helperStatus !== idleStatus)
        return null;
    return _jsx(_Fragment, { children: _jsxs("div", { className: "helper-overlay__component", children: [_jsx("span", { className: "__text", children: "The session has expired" }), _jsx("br", {}), _jsx("br", {}), _jsx("a", { className: "__link", href: "/_polo_/", children: "Return to dashboard" })] }) });
}, (prev, next) => prev.helperStatus === next.helperStatus);
